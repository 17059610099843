import.meta.glob(['@images/**'])
import "@styles/app.sass"

import { createApp } from "petite-vue";
import Swiper from "swiper";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "simple-lightbox/dist/simpleLightbox.css"
import SimpleLightbox from "simple-lightbox"

interface Header {
    opened: boolean
    handleOpenedHeader(state: boolean): void
}

const headerEl = document.querySelector('[data-header]'),
    headerScope: Header = {
        opened: false,
        handleOpenedHeader(state) {
            document.body.classList.toggle("u-hiddenOverflow", state)
        }
    }

headerEl && createApp(headerScope).mount(headerEl);

(() => {
    const slider = document.querySelector('[data-hero-slides]') as HTMLElement
    if (!slider) return

    new Swiper(slider, {
        slidesPerView: 1,
        allowTouchMove: false,
        cssMode: true,
        modules: [Pagination, Autoplay],
        pagination: {
            el: "[data-hero-pagination]",
            type: "bullets",
            clickable: true,
            renderBullet: function (index, className) {
                return `<div class="${className}">${ index + 1 }</div>`;
            }
        },
        autoplay: {
            delay: 12000
        }
    })
})();

(() => {
    const slider = document.querySelector('[data-news-slider]') as HTMLElement
    if (!slider) return

    new Swiper(slider, {
        slidesPerView: 1,
        allowTouchMove: false,
        autoHeight: true,
        modules: [Navigation],
        navigation: {
            nextEl: "[data-news-next]",
            prevEl: "[data-news-prev]",
        }
    })
})();

(() => {
    if (!document.querySelector('[data-gallery]'))
        return

    new SimpleLightbox({
        elements: '[data-gallery] > a'
    })
})();